import type { RouteLocationNormalized } from 'vue-router';

import type { AppVueRouter } from '@core/router';

import type { GetRouteSeoConfig } from 'web/src/modules/core/services/router/seo';
import { useLobbyType } from 'web/src/modules/lobby/composables';
import DefaultPageSeoController from 'web/src/modules/seo/DefaultPageSeoController';

export default class EgsPageSeoController extends DefaultPageSeoController {
  getSeoConfig(
    router: AppVueRouter,
    from: RouteLocationNormalized,
    to: RouteLocationNormalized,
  ): Promise<GetRouteSeoConfig> {
    return new Promise<GetRouteSeoConfig>((resolve) => {
      const { lobbyType } = useLobbyType();
      const params = { ...(to.params || {}), lobbyType: lobbyType.value };

      resolve({ ...to, params });
    });
  }
}
